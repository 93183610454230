import "../../assets/style/account/account.scss"
import { useAccount } from "wagmi"
import { Link, useLocation } from 'react-router-dom'
import { MdRefresh } from "react-icons/md";
import { CopyToClip } from '../common/Utils'

const Account = ({reload}) => {
    const location = useLocation()
    const { address, isConnected } = useAccount();
    const isDeposit = location.pathname === '/account/deposit';
    let tabDeposits, tabWithdrawals;

    if (isDeposit) {
        tabDeposits = <Link className="active" onClick={reload}>Deposits <MdRefresh /></Link>;
        tabWithdrawals = <Link to="/account/withdraw">Withdrawals</Link>;
    } else {
        tabDeposits = <Link to="/account/deposit">Deposits</Link>;
        tabWithdrawals = <Link className="active" onClick={reload}>Withdrawals <MdRefresh /></Link>;
    }
    return (
        <>
            <div className='account_title'>
                <h3>Account</h3>
                {
                    isConnected &&
                    <h6>
                        <span>{address}</span><CopyToClip value={address} />
                    </h6>
                }
            </div>
            <div className='account_tabs'>
                <ul>
                    <li>{tabDeposits}</li>
                    <li>{tabWithdrawals}</li>
                </ul>
            </div>
        </>
    )
}

export default Account