import React, { useState, useEffect } from 'react';
import "../assets/style/deposit.scss";
import { Form, Spinner, Image } from "react-bootstrap"
import { Link } from "react-router-dom"
import { Bnb } from 'react-web3-icons';
import ArrowsIcon from '../assets/images/arrows.svg'
import VixIcon from "../assets/images/vixco.svg"
import HefiIcon from "../assets/images/hefi.svg"
import { FaEthereum } from "react-icons/fa"
import { useAccount, useConnect, useNetwork, useBalance } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import metamask from "../assets/images/metamask.svg"
import Web3 from 'web3';
import { TokenSymbol, SwitchNetwork, popup } from './common/Utils';

const optimismSDK = require("@eth-optimism/sdk")
const ethers = require("ethers")

const Deposit = () => {
    const [ethValue, setEthValue] = useState("")
    const [sendToken, setSendToken] = useState(process.env.REACT_APP_HEFI_SYMBOL)
    const { data: accountData, address, isConnected } = useAccount()
    const [errorInput, setErrorInput] = useState("")
    const [loader, setLoader] = useState(null)
    const { chain, chains } = useNetwork()
    const [checkMetaMask, setCheckMetaMask] = useState("");

    useEffect(() => handleChange(ethValue), [sendToken]);

    const { connect } = useConnect({
        connector: new InjectedConnector({ chains }), onError(error) {
            console.log('Error', error)

            if (error.name === 'ResourceUnavailableRpcError') {
                popup('Switch to the Wallet and check', error.message);
            }
        },
        onMutate(args) {
            console.log('Mutate', args)
            if (args.connector.ready === true) {
                setCheckMetaMask(false)
            } else {
                setCheckMetaMask(true)
            }
        },
        onSettled(data, error) {
            console.log('Settled', { data, error })
        },
        onSuccess(data) {
            console.log('Success', data)
        },
    })

    const { data } = useBalance({ address: address, watch: true, chainId: Number(process.env.REACT_APP_L1_CHAIN_ID) })
    const dataHEFI = useBalance({ address: address, token: process.env.REACT_APP_HEFI_L1, watch: true, chainId: Number(process.env.REACT_APP_L1_CHAIN_ID) });
    const dataLIX = useBalance({ address: address, token: process.env.REACT_APP_LIX_L1, watch: true, chainId: Number(process.env.REACT_APP_L1_CHAIN_ID) });

    const handleDeposit = async () => {
        if (loader) {
            popup('Deposit is in progress.', 'To check the current status, please switch to the Wallet app and check.');
            return;
        }

        try {
            if (!ethValue) {
                setErrorInput("Please enter the amount");
            }
            else {
                if (!parseFloat(ethValue) > 0) {
                    setErrorInput("Invalid Amount Entered!");
                } else {
                    const l2Url = process.env.REACT_APP_L2_RPC_URL;
                    const l1Provider = new ethers.providers.Web3Provider(window.ethereum);
                    const l2Provider = new ethers.providers.JsonRpcProvider(l2Url, 'any')
                    const l1Signer = l1Provider.getSigner(address)
                    const l2Signer = l2Provider.getSigner(address)
                    const zeroAddr = "0x".padEnd(42, "0");
                    const l1Contracts = {
                        AddressManager: zeroAddr,
                        StateCommitmentChain: zeroAddr,
                        CanonicalTransactionChain: zeroAddr,
                        BondManager: zeroAddr,
                        L1CrossDomainMessenger: process.env.REACT_APP_PROXY_OVM_L1CROSSDOMAINMESSENGER,
                        L1StandardBridge: process.env.REACT_APP_PROXY_OVM_L1STANDARDBRIDGE,
                        OptimismPortal: process.env.REACT_APP_OPTIMISM_PORTAL_PROXY,
                        L2OutputOracle: process.env.REACT_APP_L2_OUTPUTORACLE_PROXY,
                    }
                    const bridges = {
                        Standard: {
                            l1Bridge: l1Contracts.L1StandardBridge,
                            l2Bridge: process.env.REACT_APP_L2_BRIDGE,
                            Adapter: optimismSDK.StandardBridgeAdapter
                        },
                        ETH: {
                            l1Bridge: l1Contracts.L1StandardBridge,
                            l2Bridge: process.env.REACT_APP_L2_BRIDGE,
                            Adapter: optimismSDK.ETHBridgeAdapter
                        }
                    }
                    const crossChainMessenger = new optimismSDK.CrossChainMessenger({
                        contracts: {
                            l1: l1Contracts,
                            l2: optimismSDK.DEFAULT_L2_CONTRACT_ADDRESSES,
                        },
                        //                        bridges: bridges,
                        l1ChainId: Number(process.env.REACT_APP_L1_CHAIN_ID),
                        l2ChainId: Number(process.env.REACT_APP_L2_CHAIN_ID),
                        l1SignerOrProvider: l1Signer,
                        l2SignerOrProvider: l2Signer,
                        //                        bedrock: true,
                    })
                    if (sendToken === process.env.REACT_APP_MAIN_SYMBOL) {
                        console.log(sendToken);
                        const weiValue = parseInt(ethers.utils.parseEther(ethValue)._hex, 16)
                        setLoader("DEPOSITING...");
                        var depositETHEREUM = await crossChainMessenger.depositETH(weiValue.toString())
                        const receiptETH = await depositETHEREUM.wait()
                        if (receiptETH) {
                            // bridge-api 에 Update 한다.
                            const timestamp = (await l1Provider.getBlock(depositETHEREUM.blockNumber)).timestamp;
                            const item = {
                                chainId : Number(process.env.REACT_APP_L1_CHAIN_ID),
                                transactionHash: depositETHEREUM.transactionHash,
                                address: address,
                                blockNumber: depositETHEREUM.blockNumber,
                                timestamp: timestamp,
                                amount: weiValue.toString(),
                                l1Token: '',
                                l2Token: '',
                                messageStatus: 6
                            };
                            await fetchDepositUpdate(item);
                                
                            setLoader(null);
                            setEthValue("")
                        }
                    }
                    if (sendToken === process.env.REACT_APP_HEFI_SYMBOL) {
                        var tokenValue = Web3.utils.toWei(ethValue, "ether")
                        setLoader("1/2 APPROVING...");
                        var depositTxn2 = await crossChainMessenger.approveERC20(process.env.REACT_APP_HEFI_L1, process.env.REACT_APP_HEFI_L2, tokenValue)
                        await depositTxn2.wait()
                        setLoader("2/2 DEPOSITING...");
                        var receiptToken = await crossChainMessenger.depositERC20(process.env.REACT_APP_HEFI_L1, process.env.REACT_APP_HEFI_L2, tokenValue)
                        var getReceiptToken = await receiptToken.wait()
                        if (getReceiptToken) {
                            // bridge-api 에 Update 한다.
                            const timestamp = (await l1Provider.getBlock(getReceiptToken.blockNumber)).timestamp;
                            const item = {
                                chainId : Number(process.env.REACT_APP_L1_CHAIN_ID),
                                transactionHash: getReceiptToken.transactionHash,
                                address: address,
                                blockNumber: getReceiptToken.blockNumber,
                                timestamp: timestamp,
                                amount: tokenValue.toString(),
                                l1Token: process.env.REACT_APP_HEFI_L1,
                                l2Token: process.env.REACT_APP_HEFI_L2,
                                messageStatus: 6
                            };
                            await fetchDepositUpdate(item);

                            setLoader(null);
                            setEthValue("")
                        }
                    }
                    if (sendToken === process.env.REACT_APP_LIX_SYMBOL) {
                        var tokenValue = Web3.utils.toWei(ethValue, "ether")
                        setLoader("1/2 APPROVING...");
                        var depositTxn2 = await crossChainMessenger.approveERC20(process.env.REACT_APP_LIX_L1, process.env.REACT_APP_LIX_L2, tokenValue)
                        await depositTxn2.wait()
                        setLoader("2/2 DEPOSITING...");
                        var receiptToken = await crossChainMessenger.depositERC20(process.env.REACT_APP_LIX_L1, process.env.REACT_APP_LIX_L2, tokenValue)
                        var getReceiptToken = await receiptToken.wait()
                        if (getReceiptToken) {
                            // bridge-api 에 Update 한다.
                            const timestamp = (await l1Provider.getBlock(getReceiptToken.blockNumber)).timestamp;
                            const item = {
                                chainId : Number(process.env.REACT_APP_L1_CHAIN_ID),
                                transactionHash: getReceiptToken.transactionHash,
                                address: address,
                                blockNumber: getReceiptToken.blockNumber,
                                timestamp: timestamp,
                                amount: tokenValue.toString(),
                                l1Token: process.env.REACT_APP_LIX_L1,
                                l2Token: process.env.REACT_APP_LIX_L2,
                                messageStatus: 6
                            };
                            await fetchDepositUpdate(item);

                            setLoader(null);
                            setEthValue("")
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error)
            popup('Deposit failed', error.message);
            setLoader(null);
        }
    }
    const [checkDisabled, setCheckDisabled] = useState(false)
    const handleChange = (value) => {
        if (sendToken == process.env.REACT_APP_MAIN_SYMBOL) {
            if (Number(data?.formatted) < value) {
                setErrorInput("Insufficient balance.")
                setCheckDisabled(true)
            } else {
                setCheckDisabled(false)
                setErrorInput("")
            }
            setEthValue(value)
        }
        if (sendToken == process.env.REACT_APP_HEFI_SYMBOL) {
            if (Number(dataHEFI.data?.formatted) < value) {
                setErrorInput("Insufficient balance.")
                setCheckDisabled(true)
            } else {
                setCheckDisabled(false)
                setErrorInput("")
            }
            setEthValue(value)
        }
        if (sendToken == process.env.REACT_APP_LIX_SYMBOL) {
            if (Number(dataLIX.data?.formatted) < value) {
                setErrorInput("Insufficient balance.")
                setCheckDisabled(true)
            } else {
                setCheckDisabled(false)
                setErrorInput("")
            }
            setEthValue(value)
        }
    }
    
    const tokenBalance = () => {
        switch (sendToken) {
            case 'HEFI':
            case 'tHEF':
                return dataHEFI.data ? dataHEFI.data.formatted : '0';
            case 'LIX':
            case 'tLIX':
                return dataLIX.data ? dataLIX.data.formatted : '0'
            case 'BNB':
            case 'tBNB':
            default:
                return data ? data.formatted : '0';
        }
    }

    const fetchDepositUpdate = async (item) => {
        console.log("fetchDepositUpdate", item);
        try {
            const bridgeApiUrl = process.env.REACT_APP_BRIDGE_API_URL + "/deposit";
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(item)
            };
            const response = await fetch(bridgeApiUrl, requestOptions);
            console.log(response.status);
            if (!response.ok) {
                throw new Error("fetch Deposit Update Fail!");
            }
            return;
        } catch (err) {
            console.log(err.message);
            popup('Deposit failed', err.message);
        }
    };

    return (
        <>
            <div className='bridge_wrap'>
                <section className='deposit_wrap'>
                    <div className='title'>Deposit</div>
                    <div className='description'>Transfer {sendToken} from {process.env.REACT_APP_L1_NETWORK_NAME} account to {process.env.REACT_APP_L2_NETWORK_NAME} account.</div>
                    <div className='deposit_price_wrap'>
                        <div className='deposit_price_title'>
                            <h5>From {process.env.REACT_APP_MAIN_SYMBOL == "ETH" ? <FaEthereum /> : <Bnb />} {process.env.REACT_APP_L1_NETWORK_NAME} network <SwitchNetwork L1 /></h5>
                        </div>
                        <div className='deposit_input_wrap'>
                            <Form>
                                <div className='input_title'>Amount</div>
                                <div className='deposit_inner_input'>
                                    <Form.Control type='number' value={ethValue} onChange={e => handleChange(e.target.value)} placeholder="0" min="0" step="any" />
                                    <Form.Select aria-label="Default select example" className='select_wrap' onChange={({ target }) => setSendToken(target.value)}>
                                        <option>{process.env.REACT_APP_HEFI_SYMBOL}</option>
                                        {/*<option>{process.env.REACT_APP_LIX_SYMBOL}</option>*/}
                                        <option>{process.env.REACT_APP_MAIN_SYMBOL}</option>
                                    </Form.Select>
                                </div>
                                <div className='input_icn_wrap'>
                                    {sendToken == "HEFI" || sendToken == "tHEF" ? <span className='input_icn'><Image src={HefiIcon} alt="HefiIcon" fluid /></span> : sendToken == "LIX" || sendToken == "tLIX" ? <span className='input_icn'><Image src={VixIcon} alt="VixIcon" fluid /></span> : <span className='input_icn'><Bnb style={{ fontSize: '1.5rem' }} /></span>}
                                </div>
                            </Form>
                            <div className='wallet_bal'>Balance: {Number(tokenBalance()).toFixed(5)} <TokenSymbol symbol={sendToken} L1 /></div>
                            {errorInput && <small className='text-danger'>{errorInput}</small>}
                        </div>
                    </div>
                    <div className='arrows'><Link to='/withdraw'><Image src={ArrowsIcon} fluid /></Link></div>
                    <div className='deposit_details_wrap'>
                        <div className="deposit_details">
                            <h5>To <Bnb style={{ fontSize: '1.5rem' }} /> {process.env.REACT_APP_L2_NETWORK_NAME} network <SwitchNetwork L2 /></h5>
                        </div>
                        <div className='deposit_inner_details'>
                            {sendToken == "HEFI" || sendToken == "tHEF" ? <span className='input_icn'><Image src={HefiIcon} alt="HefiIcon" fluid /></span> : sendToken == "LIX" || sendToken == "tLIX" ? <span className='input_icn'><Image src={VixIcon} alt="VixIcon" fluid /></span> : <span className='input_icn'> <Bnb style={{ fontSize: '1.5rem' }} /></span>}  <p> You’ll receive: {ethValue ? ethValue : "0"} <TokenSymbol symbol={sendToken} L2 /></p>
                        </div>
                    </div>
                    <div className="deposit_btn_wrap">
                        {checkMetaMask === true ?
                            <a className='btn deposit_btn' href='https://metamask.io/' target='_blank'><Image src={metamask} alt="metamask icn" fluid /> Please Install Metamask Wallet</a> :
                            !isConnected ?
                                <button className='btn deposit_btn' onClick={() => connect()}>CONNECT WALLET</button> :
                                chain.id !== Number(process.env.REACT_APP_L1_CHAIN_ID) ?
                                    <SwitchNetwork className='btn deposit_btn' L1>SWITCH TO {process.env.REACT_APP_L1_NETWORK_NAME}</SwitchNetwork> :
                                    checkDisabled ?
                                        <button className='btn deposit_btn' disabled={true}>Deposit</button> :
                                        <button className='btn deposit_btn' onClick={handleDeposit}> {loader ? <><Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner><div className="loader_message">{loader}</div></>: "DEPOSIT"} </button>}
                    </div>
                </section>
            </div>
        </>
    )
}

export default Deposit
