import React, { useEffect, useState } from 'react'
import { Table, Spinner } from "react-bootstrap";
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import { ethers } from "ethers"
import ReactPaginate from 'react-paginate';
import Account from './Account';
import { popup } from '../common/Utils';
const optimismSDK = require("@eth-optimism/sdk")

const WithdrawAccount = () => {
    const [transactionLoader, setTransactionLoader] = useState(false)
    const [loader, setLoader] = useState()
    const { address, isConnected } = useAccount()
    const [withdrawDetails, setWithdrawDetails] = useState([])
    const { chain } = useNetwork()

    const { switchNetwork } = useSwitchNetwork({
        onError(error) {
            console.log('Error', error)
            const closePopup = () => close();
            const close = popup('Network cannot be switched', <>
                <div>1. If you canceled, please close the pop-up.</div>
                <div>2. The network may not have been added. If so, please add the network.</div>
                <div>3. It may not work because the Wallet app is already running. In this case, please switch to the app and check.</div>
            </>, <>
                <button onClick={() => { closePopup(); addNetwork(); }}>Add Network</button>
                <button className="cancel" onClick={closePopup}>Cancel</button>
            </>);
        }
    });

    const addNetwork = () => {
        try {
            window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                    chainId: process.env.REACT_APP_L1_CHAIN_ID_WITH_HEX,
                    rpcUrls: [process.env.REACT_APP_L1_RPC_URL],
                    chainName: process.env.REACT_APP_L1_NETWORK_NAME,
                    nativeCurrency: {
                        name: process.env.REACT_APP_MAIN_SYMBOL,
                        symbol: process.env.REACT_APP_MAIN_SYMBOL,
                        decimals: 18
                    },
                    blockExplorerUrls: [process.env.REACT_APP_L1_EXPLORER_URL]
                }]
            }).then((data) => {
                console.log('Add successfully');
            }).catch((err) => {
                if (err.code === -32002) {
                    console.log('Error: request stuck in pending state');
                    popup('Switch to the Wallet and check', 'Request stuck in pending state');
                }
            });
        } catch (err) {
            console.log(err);
            popup('Network switch failed', err.message);
        }
    }

    const getCrossChain = async () => {
        const l2Url = String(process.env.REACT_APP_L2_RPC_URL)
        const l1Provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        const l2Provider = new ethers.providers.JsonRpcProvider(l2Url)
        const l1Signer = l1Provider.getSigner(address)
        const l2Signer = l2Provider.getSigner(address)
        const zeroAddr = "0x".padEnd(42, "0");
        const l1Contracts = {
            AddressManager: zeroAddr,
            StateCommitmentChain: zeroAddr,
            CanonicalTransactionChain: zeroAddr,
            BondManager: zeroAddr,
            L1CrossDomainMessenger: process.env.REACT_APP_PROXY_OVM_L1CROSSDOMAINMESSENGER,
            L1StandardBridge: process.env.REACT_APP_PROXY_OVM_L1STANDARDBRIDGE,
            OptimismPortal: process.env.REACT_APP_OPTIMISM_PORTAL_PROXY,
            L2OutputOracle: process.env.REACT_APP_L2_OUTPUTORACLE_PROXY,
        }
        const bridges = {
            Standard: {
                l1Bridge: l1Contracts.L1StandardBridge,
                l2Bridge: process.env.REACT_APP_L2_BRIDGE,
                Adapter: optimismSDK.StandardBridgeAdapter
            },
            ETH: {
                l1Bridge: l1Contracts.L1StandardBridge,
                l2Bridge: process.env.REACT_APP_L2_BRIDGE,
                Adapter: optimismSDK.ETHBridgeAdapter
            }
        }
        const crossChainMessenger = new optimismSDK.CrossChainMessenger({
            contracts: {
                l1: l1Contracts,
                l2: optimismSDK.DEFAULT_L2_CONTRACT_ADDRESSES,
            },
            //            bridges: bridges,
            l1ChainId: Number(process.env.REACT_APP_L1_CHAIN_ID),
            l2ChainId: Number(process.env.REACT_APP_L2_CHAIN_ID),
            l1SignerOrProvider: l1Signer,
            l2SignerOrProvider: l2Signer,
            //            bedrock: true,
        })

        return crossChainMessenger
    }
    const getWithdraw = async () => {
        console.log("getWithdraw");
        setTransactionLoader(true);
        const getCrossChainMessenger = await getCrossChain();
        const l2Url = String(process.env.REACT_APP_L2_RPC_URL)
        const l2Provider = new ethers.providers.JsonRpcProvider(l2Url)
        const l2LatestBlock = await l2Provider.getBlock("latest")
        const l2LatestBlockNumber = l2LatestBlock.number
        const l2FromBlock = l2LatestBlockNumber - 49990;

        // block search at op
        var data = await fetchWithdrawList();
        for (let index = 0; index < data.length; index++) {
            if (data[index].messageStatus != 6) {
                try {
                    let getStatus = await getCrossChainMessenger.getMessageStatus(data[index].transactionHash);
                    data[index].messageStatus = getStatus
                    // 변경된 status bridge-api 에 update 한다.
                    await fetchWithdrawUpdate(data[index]);
                } catch (error) {
                    console.log("error", error);
                }
            }
        }
        const getNewWithdrawals = data.map(object => {
            if (object.messageStatus == 6) {
                return { ...object, message: 'Completed' };
            }
            else if (object.messageStatus == 3) {
                return { ...object, message: 'Ready to Prove' };
            }
            else if (object.messageStatus == 5) {
                return { ...object, message: 'Claim Withdrawal' };
            }
            else if (object.messageStatus == 2) {
                var destTimestamp = object.timestamp + 7200;
                var timeDiff = destTimestamp - l2LatestBlock.timestamp;
                // 남은 시간이 1시간이 안되면 표시하지 않는다.
                if (timeDiff < 3600) {
                    return { ...object, message: 'Waiting for Confirmation' };
                } else {
                    return { ...object, message: 'Waiting for Confirmation ' + timeConverter2(timeDiff) };
                }
            }
            else if (object.messageStatus == 4) {
                var proveTimestamp = getProveTimestamp(getCrossChainMessenger, object.message, 0);
                var destTimestamp = proveTimestamp + 604800;
                var timeDiff = destTimestamp - l2LatestBlock.timestamp;
                // 남은 시간이 1시간이 안되면 표시하지 않는다.
                if (timeDiff < 3600) {
                    return { ...object, message: 'In challenge Period' };
                } else {
                    return { ...object, message: 'In challenge Period ' + timeConverter2(timeDiff) };
                }
            }
            else {
                return { ...object, message: null };
            }
        });
        setWithdrawDetails(getNewWithdrawals)
        setTransactionLoader(false)
        console.log(currentItemsCollections)
    }
    function timeConverter(timestamp) {
        var a = new Date(timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        return time;
    }
    function timeConverter2(timestamp) {
        console.log("timestamp", timestamp);
        // 초단위의 시간간격을 받아 dd:hh:mm:ss 형태로 변환
        var days = 0;
        if (timestamp > 60 * 60 * 24) {
            days = Math.floor(timestamp / (60 * 60 * 24));
            timestamp = timestamp - days * 60 * 60 * 24;
        }
        var hours = 0;
        if (timestamp > 60 * 60) {
            hours = Math.floor(timestamp / (60 * 60));
            timestamp = timestamp - hours * 60 * 60;
        }
        var minutes = 0;
        if (timestamp > 60) {
            minutes = Math.floor(timestamp / 60);
            timestamp = timestamp - minutes * 60;
        }
        var seconds = timestamp;
        var time = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's';
        return time;
    }
    async function getProveTimestamp(crossChainMessenger, message, messageIndex = 0) {
        const resolved = await crossChainMessenger.toCrossChainMessage(message, messageIndex);
        const withdrawal = await crossChainMessenger.toLowLevelMessage(
            resolved,
            messageIndex
        );
        const provenWithdrawal = await crossChainMessenger.getProvenWithdrawal(
            optimismSDK.hashLowLevelMessage(withdrawal)
        );
        console.log("provenWithdrawal.timestamp", provenWithdrawal.timestamp, timeConverter(provenWithdrawal.timestamp));
        return provenWithdrawal.timestamp;
    };

    const needToSwitchNetwork = () => {
        if (chain.id !== Number(process.env.REACT_APP_L1_CHAIN_ID)) {
            const closePopup = () => close();
            const close = popup('Ntwork needs to be switched', <>
                The network needs to be switched to L1.
                Please rerun the operation after switching.
            </>, <>
                <button onClick={() => { closePopup(); switchNetwork(process.env.REACT_APP_L1_CHAIN_ID); }}>Switch Network</button>
                <button className="cancel" onClick={closePopup}>Cancel</button>
            </>);
            return true;
        }

        return false;
    }

    const handleProve = async (event, transactionHash) => {
        if (needToSwitchNetwork()) {
            return;
        }

        try {
            const index = event.target.getAttribute('data-value')
            setLoader(index)
            const getCrossChainMessenger = await getCrossChain();
            const response = await getCrossChainMessenger.proveMessage(transactionHash)
            const logs = await response.wait()
            console.log("logs.status", logs.status);
            setLoader(NaN)

            if (logs.status === 1) {
                getWithdraw()
            }
        } catch (error) {
            setLoader(NaN)
            console.log(error);
            popup('Proving failed', error.message);
        }
    }

    const handleClaim = async (event, transactionHash) => {
        if (needToSwitchNetwork()) {
            return;
        }

        try {
            const index = event.target.getAttribute('data-value')
            setLoader(index)
            const getCrossChainMessenger = await getCrossChain();
            const response = await getCrossChainMessenger.finalizeMessage(transactionHash)
            const logs = await response.wait()
            console.log("logs.status", logs.status);
            setLoader(NaN)

            if (logs.status === 1) {
                getWithdraw()
            }
        } catch (error) {
            setLoader(NaN)
            console.log(error);
            popup('Claimimg failed', error.message);
        }
    }

    useEffect(() => {
        if (isConnected) {
            getWithdraw()
        }
    }, [address])
    // =============all Collections pagination start===============
    const [currentItemsCollections, setCurrentItemsCollections] = useState([]);
    const [pageCountCollections, setPageCountCollections] = useState(0);
    const [itemOffsetCollections, setItemOffsetCollections] = useState(0);
    const itemsPerPageCollections = 10;

    const tokenList = [
        {
            type: process.env.REACT_APP_HEFI_L2,
            tokenSymbol: process.env.REACT_APP_HEFI_SYMBOL,
            decimalValue: 18
        },
        {
            type: process.env.REACT_APP_LIX_L2,
            tokenSymbol: process.env.REACT_APP_LIX_SYMBOL,
            decimalValue: 18
        }
    ]

    function retrieveEthValue(amount, givenType) {
        const weiValue = parseInt(amount);
        const dynamicDecimal = tokenList.filter(a => a.type === givenType)[0]?.decimalValue === undefined ? 18 : tokenList.filter(a => a.type === givenType)[0]?.decimalValue
        return weiValue / Number("1".padEnd(dynamicDecimal + 1, 0));
    }

    useEffect(() => {
        if (withdrawDetails) {
            const endOffsetCollections = itemOffsetCollections + itemsPerPageCollections;
            setCurrentItemsCollections(withdrawDetails.slice(itemOffsetCollections, endOffsetCollections));
            setPageCountCollections(Math.ceil(withdrawDetails.length / itemsPerPageCollections));
        } else {

        }
    }, [withdrawDetails, itemOffsetCollections, itemsPerPageCollections]);

    const handlePageClickCollections = (event) => {
        const newOffsetCollections =
            (event.selected * itemsPerPageCollections) % withdrawDetails.length;
        setItemOffsetCollections(newOffsetCollections);
    };

    const fetchWithdrawList = async () => {
        try {
            const bridgeApiUrl = process.env.REACT_APP_BRIDGE_API_URL + "/withdraw/" + address + "?chainId=" + process.env.REACT_APP_L1_CHAIN_ID;
            const response = await fetch(bridgeApiUrl);
            console.log(response.status);
            if (!response.ok) {
                throw new Error("fetch Withdraw List Fail!");
            }
            const data = await response.json();
            return data;
        } catch (err) {
            console.log(err.message);
            popup('Fetching failed', err.message);
        }
    };

    const fetchWithdrawUpdate = async (item) => {
        try {
            const bridgeApiUrl = process.env.REACT_APP_BRIDGE_API_URL + "/withdraw";
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(item)
            };
            const response = await fetch(bridgeApiUrl, requestOptions);
            console.log(response.status);
            if (!response.ok) {
                throw new Error("fetch Withdraw Update Fail!");
            }
            return;
        } catch (err) {
            console.log(err.message);
            popup('Update failed', err.message);
        }
    };

    // =============all Collections pagination end===============
    // console.log("withdrawDetails", {currentItemsCollections, withdrawDetails});
    return (
        <>
            <div className="bridge_wrap">
                <div className='account_wrap'>
                    <Account reload={getWithdraw} />
                    <section className="account_withdraw_table">
                        {
                            !isConnected ? <div></div> :
                                transactionLoader ? <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> :
                                    withdrawDetails?.length <= 0 ? <h4 className='text-center'>No transaction found</h4> :
                                        <Table responsive bordered hover variant="light">
                                            <thead>
                                                <tr>
                                                    <th>Time</th>
                                                    <th>Type</th>
                                                    <th>Amount</th>
                                                    <th>Transaction</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemsCollections.map((element, index) => {
                                                    const { timestamp, message, transactionHash, amount, messageStatus, l2Token } = element
                                                    console.log("message", messageStatus)
                                                    return (
                                                        <tr key={index}>
                                                            <td>{timeConverter(timestamp)}</td>
                                                            <td>Withdraw</td>
                                                            <td>{retrieveEthValue(amount, l2Token)} {tokenList.filter(a => a.type === l2Token)[0]?.tokenSymbol === undefined ? "ETH" : tokenList.filter(a => a.type === l2Token)[0]?.tokenSymbol}</td>
                                                            <td> <a href={`${process.env.REACT_APP_L2_EXPLORER_URL}/tx/${transactionHash}`} target='_blank'> {`${transactionHash.slice(0, 8)}...${transactionHash.slice(-8)}`}</a></td>
                                                            <td>
                                                                {message}
                                                                {messageStatus === 3 ?
                                                                    index == loader ?
                                                                        <button type='button' className='btn withdraw_inner_btn'>
                                                                            <Spinner animation="border" role="status">
                                                                                <span className="visually-hidden">Loading...</span>
                                                                            </Spinner>
                                                                        </button> :
                                                                        <button type='button' className='btn withdraw_inner_btn' data-value={index} onClick={(event) => handleProve(event, transactionHash)}>
                                                                            Prove
                                                                        </button> :
                                                                    messageStatus === 5 ?
                                                                        index == loader ?
                                                                            <button type='button' className='btn withdraw_inner_btn' >
                                                                                <Spinner animation="border" role="status">
                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                </Spinner>
                                                                            </button> :
                                                                            <button type='button' className='btn withdraw_inner_btn' data-value={index} onClick={(event) => handleClaim(event, transactionHash)}>
                                                                                Claim
                                                                            </button> :
                                                                        ""}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </Table>}
                        {withdrawDetails?.length > 10 ? <div className='pagination_wrap'>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=" >>"
                                onPageChange={handlePageClickCollections}
                                pageRangeDisplayed={1}
                                marginPagesDisplayed={1}
                                pageCount={pageCountCollections}
                                previousLabel="<< "
                                containerClassName="pagination justify-content-end"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                activeClassName="active"
                            />
                        </div> : ""}
                    </section>
                </div>
            </div>
        </>
    )
}

export default WithdrawAccount